import { FC } from 'react'

export const EtherIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <rect x="5" y="16" width="1" height="1" fill="#9095b6" />
    <polygon
      points="11 19 11 18 10 18 9 18 8 18 8 17 7 17 6 17 6 18 7 18 7 19 8 19 8 20 9 20 9 21 10 21 10 22 11 22 11 23 12 23 12 22 12 21 12 20 12 19 11 19"
      fill="#9095b6"
    />
    <polygon
      points="18 14 18 13 17 13 16 13 16 12 15 12 14 12 13 12 13 11 12 11 12 12 12 13 12 14 12 15 12 16 12 17 12 18 13 18 13 17 14 17 15 17 16 17 16 16 17 16 18 16 18 15 19 15 19 14 18 14"
      fill="#4a4d7a"
    />
    <polygon
      points="11 12 10 12 9 12 8 12 8 13 7 13 6 13 6 14 5 14 5 15 6 15 6 16 7 16 8 16 8 17 9 17 10 17 11 17 11 18 12 18 12 17 12 16 12 15 12 14 12 13 12 12 12 11 11 11 11 12"
      fill="#656c93"
    />
    <polygon
      points="17 12 17 11 17 10 16 10 16 9 16 8 16 7 15 7 15 6 15 5 14 5 14 4 14 3 13 3 13 2 13 1 12 1 12 2 12 3 12 4 12 5 12 6 12 7 12 8 12 9 12 10 12 11 13 11 13 12 14 12 15 12 16 12 16 13 17 13 18 13 18 12 17 12"
      fill="#656c93"
    />
    <polygon
      points="11 1 11 2 11 3 10 3 10 4 10 5 9 5 9 6 9 7 8 7 8 8 8 9 8 10 7 10 7 11 7 12 6 12 6 13 7 13 8 13 8 12 9 12 10 12 11 12 11 11 12 11 12 10 12 9 12 8 12 7 12 6 12 5 12 4 12 3 12 2 12 1 11 1"
      fill="#8c95b7"
    />
    <polygon
      points="16 17 16 18 15 18 14 18 13 18 13 19 12 19 12 20 12 21 12 22 12 23 13 23 13 22 14 22 14 21 15 21 15 20 16 20 16 19 17 19 17 18 18 18 18 17 17 17 16 17"
      fill="#686b95"
    />
    <rect x="18" y="16" width="1" height="1" fill="#686b95" />
    <polygon points="6 16 6 15 5 15 5 14 4 14 4 15 4 16 4 17 5 17 5 16 6 16" fill="#242733" />
    <rect x="5" y="17" width="1" height="1" fill="#242733" />
    <rect x="6" y="18" width="1" height="1" fill="#242733" />
    <rect x="7" y="19" width="1" height="1" fill="#242733" />
    <rect x="8" y="20" width="1" height="1" fill="#242733" />
    <rect x="9" y="21" width="1" height="1" fill="#242733" />
    <rect x="10" y="22" width="1" height="1" fill="#242733" />
    <polygon points="11 23 11 24 12 24 13 24 13 23 12 23 11 23" fill="#242733" />
    <rect x="13" y="22" width="1" height="1" fill="#242733" />
    <rect x="14" y="21" width="1" height="1" fill="#242733" />
    <rect x="15" y="20" width="1" height="1" fill="#242733" />
    <rect x="16" y="19" width="1" height="1" fill="#242733" />
    <rect x="17" y="18" width="1" height="1" fill="#242733" />
    <rect x="18" y="17" width="1" height="1" fill="#242733" />
    <polygon points="16 16 16 17 17 17 18 17 18 16 17 16 16 16" fill="#242733" />
    <polygon points="14 17 13 17 13 18 14 18 15 18 16 18 16 17 15 17 14 17" fill="#242733" />
    <polygon points="11 18 11 19 12 19 13 19 13 18 12 18 11 18" fill="#242733" />
    <polygon points="10 17 9 17 8 17 8 18 9 18 10 18 11 18 11 17 10 17" fill="#242733" />
    <polygon points="7 16 6 16 6 17 7 17 8 17 8 16 7 16" fill="#242733" />
    <polygon points="6 13 6 12 5 12 5 13 5 14 6 14 6 13" fill="#242733" />
    <polygon points="7 11 7 10 6 10 6 11 6 12 7 12 7 11" fill="#242733" />
    <polygon points="8 9 8 8 8 7 7 7 7 8 7 9 7 10 8 10 8 9" fill="#242733" />
    <polygon points="9 6 9 5 8 5 8 6 8 7 9 7 9 6" fill="#242733" />
    <polygon points="10 4 10 3 9 3 9 4 9 5 10 5 10 4" fill="#242733" />
    <polygon points="11 2 11 1 10 1 10 2 10 3 11 3 11 2" fill="#242733" />
    <polygon points="13 1 13 0 12 0 11 0 11 1 12 1 13 1" fill="#242733" />
    <polygon points="13 3 14 3 14 2 14 1 13 1 13 2 13 3" fill="#242733" />
    <polygon points="14 5 15 5 15 4 15 3 14 3 14 4 14 5" fill="#242733" />
    <polygon points="15 7 16 7 16 6 16 5 15 5 15 6 15 7" fill="#242733" />
    <polygon points="16 9 16 10 17 10 17 9 17 8 17 7 16 7 16 8 16 9" fill="#242733" />
    <polygon points="17 12 18 12 18 11 18 10 17 10 17 11 17 12" fill="#242733" />
    <polygon points="18 14 19 14 19 13 19 12 18 12 18 13 18 14" fill="#242733" />
    <polygon points="19 14 19 15 18 15 18 16 19 16 19 17 20 17 20 16 20 15 20 14 19 14" fill="#242733" />
  </svg>
)
