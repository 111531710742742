import Aku from 'components/Icons/Aku.png'
import { CoinsIcon } from 'components/Icons/Coins'
import Eli from 'components/Icons/Eli.png'
import { EtherIcon } from 'components/Icons/Ether'
import { OwlIcon } from 'components/Icons/Owl'
import { StarkPEPEIcon } from 'components/Icons/StarkPEPE'
import STRK from 'components/Icons/STRK.png'
import Tony from 'components/Icons/Tony.png'

export interface Token {
  address: string
  symbol: string
  decimals: number
  camelCased?: boolean
  getIcon: () => JSX.Element
}

export const ETH: Token = {
  address: '0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7',
  symbol: 'ETH',
  decimals: 18,
  camelCased: true,
  getIcon: () => <EtherIcon />,
}

export const TOKENS_LIST: Token[] = [
  ETH,
  {
    address: '0x1e0eee22c684fdf32babdd65e6bcca62a8ce2c23c8d5e68f3989595d26e1b4a',
    symbol: 'SPEPE',
    decimals: 18,
    getIcon: () => <StarkPEPEIcon />,
  },
  {
    address: '0x3a6ec0b0ea7a1903329d5dec4bb574ecb4d6fdc206664e1c61eeded8158ab40',
    symbol: 'BLACK',
    decimals: 18,
    getIcon: () => <CoinsIcon color="#000000" />,
  },
  {
    address: '0x79337c58d486aec3f2c5648271345d7c54657a158d88a1e3bd3521129c7cc94',
    symbol: 'POWNI',
    decimals: 18,
    getIcon: () => <CoinsIcon color="#FFC71A" />,
  },
  {
    address: '0x62e09aedf6ac5718cf1a088eb5702ba6b98244399b4422cd2bc75ba7797e961',
    symbol: 'SKDOGE',
    decimals: 18,
    camelCased: true,
    getIcon: () => <CoinsIcon color="#0b0c4f" />,
  },
  {
    address: '0x137dfca7d96cdd526d13a63176454f35c691f55837497448fad352643cfe4d4',
    symbol: 'AKU',
    decimals: 18,
    camelCased: true,
    getIcon() {
      return <img src={Aku} style={{ width: '60px', height: '60px' }} />
    },
  },
  {
    address: '0x5f467ace847d1cbc6d1efea978752a8b4549fec043286fec1289d19b8c57e67',
    symbol: 'TONY',
    decimals: 18,
    camelCased: true,
    getIcon() {
      return <img src={Tony} style={{ width: '60px', height: '60px' }} />
    },
  },

  {
    address: '0x22efee3a59b1d25cd6e2a6c5609cc8d7810ecdfff0be41796c1841125a6bd86',
    symbol: 'ELI',
    decimals: 18,
    camelCased: true,
    getIcon() {
      return <img src={Eli} style={{ width: '60px', height: '60px' }} />
    },
  },

  {
    address: '0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d',
    symbol: 'STRK',
    decimals: 18,
    camelCased: true,
    getIcon() {
      return <img src={STRK} style={{ width: '60px', height: '60px' }} />
    },
  },
]

export const OWL: Token = {
  address: '0x39877a272619050ab8b0e3e0a19b58d076fc2ce84da1dc73b699590e629f2b8',
  symbol: 'OWL',
  decimals: 18,
  getIcon: () => <OwlIcon />,
}
