import { Row } from 'components/Flex'
import { OwlIcon } from 'components/Icons/Owl'
import Web3Status from 'components/Web3Status'
import { styled } from 'styled-components'

const StyledHeader = styled(Row)`
  position: sticky;
  z-index: 1000;
  padding: 16px 24px;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  align-items: center;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width: 32px;
  height: 32px;
  image-rendering: pixelated;
`

const LogoText = styled.span`
  margin-left: 8px;
  font-size: 25px;
  color: #ffffff;
`

export default function Header() {
  return (
    <StyledHeader>
      <LogoContainer>
        <OwlIcon style={{ width: '75px', height: '75px' }} />
        <LogoText>OWL ZAPPER</LogoText>
      </LogoContainer>
      <Web3Status />
    </StyledHeader>
  )
}
