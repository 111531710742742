import { FC } from 'react'

export const CoinsIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props}>
    <g id="c">
      <polygon
        points="13 8.5 13 7.5 12 7.5 12 3.5 11 3.5 11 2.5 10 2.5 10 1.5 7 1.5 7 2.5 6 2.5 6 3.5 3 3.5 3 4.5 2 4.5 2 5.5 1 5.5 1 8.5 2 8.5 2 9.5 3 9.5 3 10.5 6 10.5 6 9.5 7 9.5 7 11.5 8 11.5 8 12.5 9 12.5 9 13.5 12 13.5 12 12.5 13 12.5 13 11.5 14 11.5 14 8.5 13 8.5"
        fill="currentColor"
      />
      <polygon
        points="14 7.5 14 6.5 13 6.5 13 5.5 13 4.5 13 3.5 13 2.5 12 2.5 12 1.5 11 1.5 11 .5 10 .5 9 .5 8 .5 7 .5 6 .5 6 1.5 5 1.5 5 2.5 4 2.5 3 2.5 3 3.5 4 3.5 5 3.5 6 3.5 6 2.5 7 2.5 7 1.5 8 1.5 9 1.5 10 1.5 10 2.5 11 2.5 11 3.5 12 3.5 12 4.5 12 5.5 12 6.5 12 7.5 13 7.5 13 8.5 14 8.5 14 9.5 14 10.5 14 11.5 13 11.5 13 12.5 12 12.5 12 13.5 11 13.5 10 13.5 9 13.5 9 12.5 8 12.5 8 11.5 7 11.5 7 10.5 7 9.5 6 9.5 6 10.5 5 10.5 4 10.5 3 10.5 3 9.5 2 9.5 2 8.5 1 8.5 1 7.5 1 6.5 1 5.5 2 5.5 2 4.5 3 4.5 3 3.5 2 3.5 1 3.5 1 4.5 0 4.5 0 5.5 0 6.5 0 7.5 0 8.5 0 9.5 1 9.5 1 10.5 2 10.5 2 11.5 3 11.5 4 11.5 5 11.5 6 11.5 6 12.5 7 12.5 7 13.5 8 13.5 8 14.5 9 14.5 10 14.5 11 14.5 12 14.5 13 14.5 13 13.5 14 13.5 14 12.5 15 12.5 15 11.5 15 10.5 15 9.5 15 8.5 15 7.5 14 7.5"
        fill="#2b2e29"
      />
      <g opacity=".3">
        <polygon points="9 3.5 10 3.5 10 2.5 9 2.5 8 2.5 7 2.5 7 3.5 8 3.5 9 3.5" fill="#2b2e29" />
        <rect x="6" y="3.5" width="1" height="1" fill="#2b2e29" />
        <polygon points="5 4.5 4 4.5 3 4.5 3 5.5 4 5.5 5 5.5 5 4.5" fill="#2b2e29" />
        <rect x="2" y="5.5" width="1" height="1" fill="#2b2e29" />
        <rect x="5" y="6.5" width="1" height="1" fill="#2b2e29" />
        <polygon points="12 6.5 11 6.5 11 7.5 10 7.5 10 8.5 11 8.5 12 8.5 12 7.5 12 6.5" fill="#2b2e29" />
        <rect x="10" y="3.5" width="1" height="1" fill="#2b2e29" />
        <rect x="12" y="8.5" width="1" height="1" fill="#2b2e29" />
        <polygon points="8 8.5 7 8.5 7 9.5 8 9.5 9 9.5 10 9.5 10 8.5 9 8.5 8 8.5" fill="#2b2e29" />
      </g>
      <g opacity=".54">
        <rect x="6" y="5.5" width="1" height="1" fill="#fff" />
        <polygon points="8 7.5 9 7.5 10 7.5 10 6.5 9 6.5 8 6.5 7 6.5 7 7.5 8 7.5" fill="#fff" />
        <rect x="10" y="5.5" width="1" height="1" fill="#fff" />
        <rect x="8" y="10.5" width="1" height="1" fill="#fff" />
        <polygon points="10 11.5 9 11.5 9 12.5 10 12.5 11 12.5 12 12.5 12 11.5 11 11.5 10 11.5" fill="#fff" />
        <rect x="12" y="10.5" width="1" height="1" fill="#fff" />
        <polygon points="4 8.5 3 8.5 3 9.5 4 9.5 5 9.5 6 9.5 6 8.5 5 8.5 4 8.5" fill="#fff" />
        <rect x="2" y="7.5" width="1" height="1" fill="#fff" />
      </g>
    </g>
  </svg>
)
