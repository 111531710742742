import { FC } from 'react'

export const StarkPEPEIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
    <g id="c">
      <polygon
        points="9 2.5 9 1.5 8 1.5 8 2.5 7 2.5 7 1.5 6 1.5 5 1.5 4 1.5 4 2.5 3 2.5 3 3.5 3 4.5 3 5.5 3 6.5 2 6.5 2 7.5 2 8.5 2 9.5 2 10.5 3 10.5 3 9.5 3 8.5 3 7.5 4 7.5 4 6.5 4 5.5 4 4.5 4 3.5 5 3.5 6 3.5 7 3.5 7 4.5 7 5.5 7 6.5 6 6.5 5 6.5 5 7.5 6 7.5 7 7.5 8 7.5 8 6.5 8 5.5 8 4.5 8 3.5 9 3.5 10 3.5 10 2.5 9 2.5"
        fill="#73b949"
      />
      <polygon
        points="8 9.5 7 9.5 6 9.5 5 9.5 4 9.5 4 10.5 5 10.5 6 10.5 7 10.5 8 10.5 9 10.5 10 10.5 10 9.5 9 9.5 8 9.5"
        fill="#73b949"
      />
      <rect x="9" y="6.5" width="1" height="1" fill="#73b949" />
      <rect x="8" y="6.5" width="1" height="1" fill="#61a344" />
      <rect x="4" y="6.5" width="1" height="1" fill="#61a344" />
      <polygon
        points="3 5.5 3 4.5 3 3.5 2 3.5 2 4.5 1 4.5 1 5.5 1 6.5 1 7.5 1 8.5 1 9.5 2 9.5 2 8.5 2 7.5 2 6.5 3 6.5 3 5.5"
        fill="#61a344"
      />
      <rect x="3" y="9.5" width="1" height="1" fill="#61a344" />
      <polygon points="5 3.5 4 3.5 4 4.5 5 4.5 6 4.5 7 4.5 7 3.5 6 3.5 5 3.5" fill="#61a344" />
      <polygon points="10 3.5 9 3.5 8 3.5 8 4.5 9 4.5 10 4.5 11 4.5 11 3.5 10 3.5" fill="#61a344" />
      <polygon
        points="10 4.5 9 4.5 8 4.5 8 5.5 8 6.5 9 6.5 9 5.5 10 5.5 10 6.5 11 6.5 11 5.5 11 4.5 10 4.5"
        fill="#fff"
      />
      <polygon points="5 4.5 4 4.5 4 5.5 4 6.5 5 6.5 5 5.5 6 5.5 6 6.5 7 6.5 7 5.5 7 4.5 6 4.5 5 4.5" fill="#fff" />
      <rect x="5" y="5.5" width="1" height="1" fill="#090909" />
      <rect x="9" y="5.5" width="1" height="1" fill="#090909" />
      <polygon
        points="10 7.5 9 7.5 8 7.5 7 7.5 6 7.5 5 7.5 4 7.5 3 7.5 3 8.5 4 8.5 5 8.5 6 8.5 7 8.5 8 8.5 9 8.5 10 8.5 11 8.5 11 7.5 10 7.5"
        fill="#f16475"
      />
      <polygon
        points="10 8.5 9 8.5 8 8.5 7 8.5 6 8.5 5 8.5 4 8.5 3 8.5 3 9.5 4 9.5 5 9.5 6 9.5 7 9.5 8 9.5 9 9.5 10 9.5 11 9.5 11 8.5 10 8.5"
        fill="#d74859"
      />
      <rect x="10" y="2.5" width="1" height="1" fill="#a4d177" />
      <rect x="9" y="1.5" width="1" height="1" fill="#a4d177" />
      <polygon points="11 9.5 12 9.5 12 8.5 12 7.5 11 7.5 11 8.5 11 9.5" fill="#1a2715" />
      <rect x="10" y="9.5" width="1" height="1" fill="#1a2715" />
      <polygon
        points="8 10.5 7 10.5 6 10.5 5 10.5 4 10.5 3 10.5 2 10.5 2 11.5 3 11.5 4 11.5 5 11.5 6 11.5 7 11.5 8 11.5 9 11.5 10 11.5 10 10.5 9 10.5 8 10.5"
        fill="#1a2715"
      />
      <rect x="1" y="9.5" width="1" height="1" fill="#1a2715" />
      <polygon points="1 7.5 1 6.5 1 5.5 1 4.5 0 4.5 0 5.5 0 6.5 0 7.5 0 8.5 0 9.5 1 9.5 1 8.5 1 7.5" fill="#1a2715" />
      <rect x="1" y="3.5" width="1" height="1" fill="#1a2715" />
      <rect x="2" y="2.5" width="1" height="1" fill="#1a2715" />
      <rect x="3" y="1.5" width="1" height="1" fill="#1a2715" />
      <polygon points="6 1.5 7 1.5 7 .5 6 .5 5 .5 4 .5 4 1.5 5 1.5 6 1.5" fill="#1a2715" />
      <rect x="7" y="1.5" width="1" height="1" fill="#1a2715" />
      <polygon points="10 1.5 10 .5 9 .5 8 .5 8 1.5 9 1.5 10 1.5" fill="#1a2715" />
      <rect x="10" y="1.5" width="1" height="1" fill="#1a2715" />
      <polygon points="11 2.5 11 3.5 11 4.5 11 5.5 11 6.5 12 6.5 12 5.5 12 4.5 12 3.5 12 2.5 11 2.5" fill="#1a2715" />
      <rect x="10" y="6.5" width="1" height="1" fill="#1a2715" />
    </g>
  </svg>
)
